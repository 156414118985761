<template>
  <div class="tlw-relative">
    <div v-if="isSalesPageModal">
      <div>
        <h4 class="font-weight-bold mb-5 tlw-text-center tlw-mt-3">
          {{ $t('sparkmembers.offers.offers-list.modal.title') }}
        </h4>
        <div>
          <hs-loading v-if="isLoading || isDeleting" />
          <template v-else>
            <template>
              <div class="tlw-w-full">
                <div class="tlw-flex tlw-flex-wrap">
                  <div
                    class="tlw-mr-0 tlw-mb-2 tlw-w-full lg:tlw-w-auto"
                    v-for="(payment_option, index) in offersList"
                    :key="index"
                  >
                    <hs-card
                      :img-src="imageURL(payment_option.kind)"
                      :badges="generateBadge(payment_option.default)"
                      :actions="[]"
                      class="h-100 offer-card tlw-mr-0 lg:tlw-mr-2"
                      v-if="!payment_option.default"
                    >
                      <div class="tlw-flex tlw-text-xs tlw-text-gray-600" v-if="payment_option.title">
                        <p class="mr-1 mb-0">
                          {{
                            payment_option.kind === 'subscription'
                              ? $t(`sparkmembers.offers.v2.${payment_option.period}`)
                              : $t('sparkmembers.offers.v2.was')
                          }}
                        </p>
                        <p v-if="payment_option.kind !== 'subscription'" class="base-price mb-0">
                          {{ payment_option.base_price | currency }}
                        </p>
                      </div>
                      <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="payment_option.title">
                        <p class="mr-1 mb-0" v-if="payment_option.kind !== 'subscription'">
                          {{ $t('sparkmembers.offers.v2.is') }}
                        </p>
                        <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ payment_option.price | currency }}</p>
                      </div>
                      <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="!payment_option.title">
                        <p class="mr-1 mb-0">{{ $t('sparkmembers.offers.v2.value') }}</p>
                        <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ payment_option.price | currency }}</p>
                      </div>
                      <p class="font-size-sm text-secondary mb-4">
                        {{ $t('sparkmembers.offers.offers-list.card.accept-only') }}
                        <strong>
                          {{ formatAcceptedPaymentOptions(payment_option.payment_method) }}
                        </strong>
                      </p>
                      <div class="d-flex tlw-justify-between">
                        <m-button
                          variant="primary-outline"
                          class="tlw-text-blue-700 tlw-w-full"
                          @click="editOffer(payment_option, true)"
                        >
                          {{ $t('sparkmembers.offers.offers-list.modal.select-offer') }}
                        </m-button>
                      </div>
                    </hs-card>
                  </div>
                </div>
                <div class="tlw-mt-8 tlw-mb-5">
                  <h4 class="tlw-font-bold tlw-text-base">{{ $t('sparkmembers.offers.v2.title-type') }}</h4>
                </div>
                <div class="tlw-flex tlw-flex-col lg:tlw-flex-row">
                  <template v-for="(type, index) in types">
                    <CardOfferType :type="type" :key="index" @selected="openCreateModal(type)" />
                  </template>
                </div>
              </div>
              <input type="hidden" ref="link-checkout" />
            </template>
          </template>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tlw-flex tlw-flex-col">
        <m-button
          class="p-0 tlw-my-4 tlw-h-9 tlw-w-64"
          variant="primary-outline"
          @click="() => $bvModal.show('new-modal')"
        >
          <span class="tlw-text-sm tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.offers.column.link') }}
          </span>
        </m-button>
        <div v-for="payment_option in offersList" :key="payment_option.id">
          <hs-card
            :img-src="imageURL(payment_option.kind)"
            :badges="generateBadge(payment_option.default)"
            :actions="[]"
            class="offer-card offer-card--horizontal tlw-mt-0"
            v-if="payment_option.default"
          >
            <div class="tlw-flex tlw-text-xs tlw-text-gray-600" v-if="payment_option.title">
              <p class="mr-1 mb-0">
                {{
                  payment_option.kind === 'subscription'
                    ? $t(`sparkmembers.offers.v2.${payment_option.period}`)
                    : $t('sparkmembers.offers.v2.was')
                }}
              </p>
              <p v-if="payment_option.kind !== 'subscription'" class="base-price mb-0">
                {{ payment_option.base_price | currency }}
              </p>
            </div>
            <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="payment_option.title">
              <p class="mr-1 mb-0" v-if="payment_option.kind !== 'subscription'">
                {{ $t('sparkmembers.offers.v2.is') }}
              </p>
              <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ payment_option.price | currency }}</p>
            </div>
            <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="!payment_option.title">
              <p class="mr-1 mb-0">{{ $t('sparkmembers.offers.v2.value') }}</p>
              <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ payment_option.price | currency }}</p>
            </div>
            <p class="font-size-sm text-secondary tlw-mb-0 tlw-flex tlw-justify-between">
              {{ $t('sparkmembers.offers.offers-list.card.accept-only') }}
              <strong>
                {{ formatAcceptedPaymentOptions(payment_option.payment_method) }}
              </strong>
            </p>
          </hs-card>
        </div>
      </div>
    </div>
    <hsConfirmModal
      id="delete-offer-modal"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('sparkmembers.offers.edit.delete-modal.title')"
      :description="$t('sparkmembers.offers.edit.delete-modal.description')"
      :ok-title="$t('sparkmembers.offers.edit.delete-modal.ok-title')"
      :cancel-title="$t('sparkmembers.offers.edit.delete-modal.cancel-title')"
      @hidden="() => (offer = null)"
      @ok="editOffer(offer, false)"
    />
    <!--Modal -->
    <b-modal id="new-modal" modalClass="new-modal" :hideHeader="false" hide-footer size="xl">
      <Offer @set-default-offer="setOffer" />
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import { gatewayService, productService } from '@/services';
import { hsLoading, hsConfirmModal } from '@/components';
import CardOfferType from './components/Card';
import MButton from '@/shared/components/MButton.vue';
import { formatAcceptedPaymentOptions } from '@/libs/paymentMethods';
import { makeCheckoutUrl } from '@/libs/ecommerce';

import Offer from './components/Offer';

export default {
  props: {
    isSalesPageModal: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [Clipboard],
  data() {
    return {
      showWarningAddWalletAlert: false,
      showDangerProductPublished: false,
      isLoading: false,
      isDeleting: false,
      offerList: [],
      selectedType: {},
      payment_options: [],
      edit: false,
      showPayment: false,
      offer: null,
      isUpdating: false,
      offerId: null,
      typeOffer: 'list',
      types: [
        {
          title: 'paid',
          kind: 'common',
          icon: 'money-bill-wave',
          backgroundColor: '#AFFFD0',
          color: '#34B469',
          badges: [],
        },
        {
          title: 'subscription',
          icon: 'file-signature',
          kind: 'subscription',
          backgroundColor: '#BCFBFF',
          disabled: !this.$FCL(['members_subscription_offers']),
          color: '#00B9C5',
          badges: [],
        },
      ],
      imagePaid: require(`@/assets/images/SparkMembers/OffersProduct/default.svg`),
      imageSubscription: require('@/assets/images/SparkMembers/OffersProduct/subscription.svg'),
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('product', {
      product: state => state.selectedProduct,
      productPaymentOptions: state => state.productPaymentOptions,
    }),
    ...mapState('school', {
      school: state => state.selectedSchool.id,
    }),
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    offersList() {
      const list = this.productPaymentOptions.map(payment => ({
        ...payment,
        price: payment.price / 100,
        base_price: payment.base_price / 100,
      }));
      return list;
    },
  },
  components: {
    hsLoading,
    hsConfirmModal,
    CardOfferType,
    MButton,
    Offer,
  },
  filters: {
    currency(value) {
      return GeneralHelper.currency(value);
    },
  },
  methods: {
    formatAcceptedPaymentOptions,
    ...mapActions('product', ['getProductPaymentOptions', 'getProductPaymentOption']),
    setOffer() {
      this.fetchPaymentOptions();
    },

    backgroundCard(kind) {
      const image = {
        common: '#AFFFD0',
        subscription: '#BCFBFF',
      };
      return image[kind];
    },
    async fetchPaymentOptions() {
      this.isLoading = true;
      try {
        this.offerList = await this.getProductPaymentOptions({ id: this.$route.params.id });
        this.offerList = this.offerList.map(payment => ({
          ...payment,
          price: payment.price / 100,
          base_price: payment.base_price / 100,
        }));

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    imageURL(kind) {
      const image = {
        common: this.imagePaid,
        subscription: this.imageSubscription,
      };
      return image[kind];
    },
    generateBadge(badge) {
      return badge
        ? [
            {
              label: this.$t('sparkmembers.offers.offers-list.card.badge'),
              variant: 'primary',
            },
          ]
        : [];
    },
    copyToClipboard(paymentOption) {
      const ref = 'link-checkout';
      let link = makeCheckoutUrl(this.hasSparkCheckout, paymentOption, this.product, this.getDomain);

      TrackingHelper.trackCheckoutLinkCopied('offers_list');
      try {
        const result = this.clipboardCopy(ref, link);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t('sparkmembers.offers.offers-list.copy-link.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.offers-list.copy-link.error'));
      }
    },
  },
  mounted() {
    this.fetchPaymentOptions();
  },
};
</script>
<style lang="scss" scoped>
.card-offer-sales {
  width: 100%;
}

/deep/ .modal .modal-header {
  border: none;
  position: absolute;
  right: 0;
  z-index: 2;

  button {
    margin: 0;
  }
}
/deep/ .offer-card {
  border: 2px solid #d3d3d3;
  .card-badges {
    font-size: 12px;
    left: 1rem;
  }
  .badge {
    background-color: #5e0ee0;
    padding: 6px;
  }
  .delete-button {
    color: var(--color-red-500) !important;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
  .base-price {
    text-decoration: line-through;
  }
  @media (max-width: $screen-sm) {
    .card-img {
      display: none;
    }
    .card-badges {
      top: 1px;
    }
    .card-body {
      padding: 1rem;
      position: relative;

      &::before {
        content: ' ';
        height: 10px;
        background-color: var(--cardColor);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
}
.offer-type {
  width: auto;
}
.product-setup-offers__offer-type-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-bottom: 36px;
}

.product-setup-offers__subtitle {
  color: #6f6f6f;
}

.product-setup-offers__title {
  color: $grey;
}
/deep/ .offer-card {
  position: relative;
  border: 2px solid #d3d3d3;
  .card-header {
    position: initial;
  }
  .card-badges {
    font-size: 12px;
    left: 1rem;
  }
  .badge {
    background-color: #5e0ee0;
  }
  .delete-button {
    color: var(--color-red-500) !important;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
  .base-price {
    text-decoration: line-through;
  }
  .card-title {
    width: 215px;
  }
  &--horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 105px;
    width: 657px;

    .card-img {
      width: auto;
    }
    .card-badges {
      bottom: 102px;
      left: 25px;
    }
    .card-body {
      padding: 0.8rem 1rem 0.8rem 1.5rem;
    }
    .card-title {
      margin-bottom: 0.3rem;
      width: 100%;
    }
    @media (max-width: $screen-sm) {
      width: 100%;

      .card-badges {
        top: 1px;
      }
      .card-img {
        display: none;
      }
      .card-body {
        padding: 1rem;
        position: relative;

        &::before {
          content: ' ';
          height: 10px;
          background-color: #bcfbff;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0px;
        }
      }
    }
  }
}
.product-setup-offers__offer-type-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0;
  margin-bottom: 36px;

  @media (min-width: $screen-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product-setup-offers__subtitle {
  color: #6f6f6f;
}

.product-setup-offers__title {
  color: $grey;
}
</style>
