<template>
  <div
    id="offer-type-card"
    :class="[
      'offer-type tlw-w-full tlw-h-20 md:tlw-h-36 tlw-mr-7 tlw-flex tlw-flex-row md:tlw-flex-col md:tlw-justify-center md:tlw-items-center md:tlw-w-56 tlw-my-2 md:tlw-mb-2 tlw-relative',
      !type.disabled && 'tlw-cursor-pointer',
    ]"
    @click="!type.disabled && $emit('selected', type)"
  >
    <div
      class="offer-type__icon md:tlw-mb-4 tlw-mr-3 md:tlw-mr-0"
      :style="{ backgroundColor: type.backgroundColor, color: type.color }"
    >
      <hs-icon variant="solid" :icon="type.icon" />
    </div>
    <hs-badge class="offer-type__soon" v-if="type.disabled" pill variant="success">
      {{ $t('sparkmembers.offers.card-type.soon') }}
    </hs-badge>
    <div class="offer-type__content tlw-flex tlw-flex-col">
      <span class="tlw-text-sm">{{ $t('sparkmembers.offers.card-type.title') }}</span>
      <strong class="tlw-text-sm md:tlw-text-center">{{ $t(`sparkmembers.offers.card-type.${type.title}`) }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.offer-type {
  border: 1px solid #d3d3d3;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 3px;
  background-color: white;
  .fa,
  .fas {
    font-weight: 400;
    font-size: 20px;
  }
  &__soon {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 6px;
    background-color: #009aa4;
  }
  &__icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }
  &__premium {
    background-color: #ffedba;
    color: #deb741;
    height: 29px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
