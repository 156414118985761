<template>
  <div class="">
    <hs-loading v-if="isLoading" />
    <div v-else-if="offerList.length">
      <h3 class="title">Adicione uma nova oferta na sua página de vendas</h3>
      <h4 class="subtitle">Escolha qual oferta vai aparecer na sua página de vendas.</h4>
      <div class="grid">
        <div class="card" v-for="(offer, index) in offerList" :key="index">
          <img :src="imageURL(offer.kind)" />
          <div v-if="isActiveOffer(offer.id)" class="card__pill">
            <hs-badge pill> Página de vendas </hs-badge>
          </div>
          <div class="card__content">
            <h4 class="card-title">
              {{ offer.title || $t('sparkmembers.offers.offers-list.card.title') }}
            </h4>
            <div class="card__price" v-if="offer.title">
              <div class="card__price-old">
                <p class="card__price-old-prefix">
                  {{
                    offer.kind === 'subscription'
                      ? $t(`sparkmembers.offers.v2.${offer.period}`)
                      : $t('sparkmembers.offers.v2.was')
                  }}
                </p>
                <p v-if="offer.kind !== 'subscription'" class="card__price-old-base">
                  {{ formatCurrency(offer.base_price / 100) }}
                </p>
              </div>
              <p class="card__price-is" v-if="offer.kind !== 'subscription'">
                {{ $t('sparkmembers.offers.v2.is') }}
              </p>
              <p class="card__price-base">{{ formatCurrency(offer.price / 100) }}</p>
            </div>
            <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="!offer.title">
              <p class="mr-1 mb-0">{{ $t('sparkmembers.offers.v2.value') }}</p>
              <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ formatCurrency(offer.price / 100) }}</p>
            </div>
            <p class="card__payments">
              <template v-if="offer.payment_method.length === 1">
                {{ $t('sparkmembers.offers.offers-list.card.accept-only') }}
                <strong>
                  {{ $t(`sparkmembers.offers.offers-list.card.payment_options.${offer.payment_method[0]}`) }}
                </strong>
              </template>
              <template v-else>
                <span v-html="$t('sparkmembers.offers.offers-list.card.accept')" />
                <strong>{{ formatAcceptedPaymentOptions(offer.payment_method) }} </strong>
              </template>
            </p>
            <m-button
              @click="selectOffer(offer, index)"
              class="card__button"
              :disabled="isActiveOffer(offer.id) || isLoadingSetNewOffer.includes(index)"
              :loading="isLoadingSetNewOffer.includes(index)"
              :icon="isActiveOffer(offer.id) ? 'check' : ''"
              :label="
                isActiveOffer(offer.id)
                  ? 'Oferta selecionada'
                  : isLoadingSetNewOffer.includes(index)
                  ? ''
                  : 'Selecionar'
              "
            />
          </div>
        </div>
      </div>
      <input type="hidden" ref="link-checkout" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import { formatAcceptedPaymentOptions } from '@/libs/paymentMethods';

export default {
  mixins: [Clipboard],
  data() {
    return {
      isLoading: false,
      isLoadingSetNewOffer: [],
      offerList: [],
      activeOfferId: {},
      imageFree: require(`@/assets/images/SparkMembers/OffersProduct/free.svg`),
      imagePaid: require(`@/assets/images/SparkMembers/OffersProduct/default.svg`),
      imageSubscription: require('@/assets/images/SparkMembers/OffersProduct/subscription.svg'),
    };
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
      productPaymentOptions: state => state.productPaymentOptions,
    }),
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
  },
  components: {
    hsLoading,
    MButton,
  },
  methods: {
    formatAcceptedPaymentOptions,
    ...mapActions('product', ['getProductPaymentOptions']),
    ...mapActions('product', ['updateProductPaymentOption']),

    isActiveOffer(offerId) {
      return this.activeOfferId === offerId;
    },

    async selectOffer(offer, offerIndex) {
      try {
        if (!this.isLoadingSetNewOffer.includes(offerIndex)) {
          this.isLoadingSetNewOffer.push(offerIndex);
        }

        const newOffer = await this.updateProductPaymentOption({
          ...offer,
          default: true,
        });

        this.activeOfferId = newOffer.id;
        this.$emit('set-default-offer');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.toast.select-offer-error'));
      } finally {
        this.isLoadingSetNewOffer = [];
      }
    },

    backgroundCard(kind) {
      const image = {
        common: '#AFFFD0',
        subscription: '#BCFBFF',
      };
      return image[kind];
    },
    async fetchPaymentOptions() {
      try {
        this.isLoading = true;
        this.offerList = await this.getProductPaymentOptions({ id: this.$route.params.id });
        this.activeOfferId = this.offerList.find(offer => offer.default === true)?.id;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.toast.error'));
      } finally {
        this.isLoading = false;
      }
    },

    imageURL(kind) {
      const image = {
        common: this.imagePaid,
        subscription: this.imageSubscription,
      };
      return image[kind];
    },
    generateBadge(badge) {
      return badge
        ? [
            {
              label: this.$t('sparkmembers.offers.offers-list.card.badge'),
              variant: 'primary',
            },
          ]
        : [];
    },
    formatCurrency(value) {
      return GeneralHelper.currency(value);
    },
  },
  mounted() {
    this.fetchPaymentOptions();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  color: #262626;
  font-size: 1.25rem;
}

.subtitle {
  color: #262626;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    .card {
      border-top: 12px solid #ead3fe;

      & > img {
        display: none;
      }
      &__pill {
        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;
        height: auto;
        & .badge {
          position: relative;
          margin-top: 1.5rem;
        }
      }
      &__pill + .card__content {
        padding-top: 0;
      }

      &__content {
        .card__payments {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    .title {
      padding-right: 16px;
    }
  }
}

@media (max-width: 575px) {
  .title {
    padding-right: 16px;
  }
}

.card {
  &__pill {
    position: relative;

    & > .badge {
      background-color: #7427f1;
      padding: 4px 12px;
      position: absolute;
      left: 20px;
      transform: translateY(-50%);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  &__content {
    h4 {
      width: 100%;
      height: 2.5rem;
      margin-bottom: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    display: flex;
    flex-direction: column;
    height: 100%;

    padding: 1.25rem;

    border: 1px solid #cfcfcf;
    border-top-color: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__price {
    color: #262626;

    &-old {
      display: flex;
      font-size: 0.75rem;

      &-prefix,
      &-base {
        margin-bottom: 0;
        margin-right: 0.2rem;
      }

      &-base {
        text-decoration: line-through;
      }
    }

    &-is,
    &-base {
      display: inline;
      margin-bottom: 0;
      font-size: 1rem;
      line-height: 1rem;
    }

    &-base {
      font-weight: 700;
      color: #348535;
    }
  }

  &__payments {
    height: 40px;
    margin: 1rem 0;

    color: #6f6f6f;
  }

  &__button {
    height: 36px;
    i {
      margin-right: 0.5rem;
    }
    border: 0;

    &:disabled {
      background-color: transparent;
      border: none;
      color: #348535;
    }

    background-color: #7427f1;
    color: #fff;
  }
}
</style>
